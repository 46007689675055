'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaData.factory:DispatchUnit

 # @description

###
angular
  .module 'lpaData'
  .factory 'DispatchUnit', [
    'Restangular'
    (Restangular) ->
      Restangular.service('lpa/dispatch/units')
  ]
